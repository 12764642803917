<template>
	<div class="section">
		<div class="container">
			<div class="columns">
				<div class="column">
					<div class="card">
						<div class="card-header">
							<div class="card-header-title is-centered">
								Last 30 days
							</div>
						</div>
						<div class="card-content" style="min-height: 200px;">
							<div class="has-text-centered" v-if="loading">
								<loading></loading>
							</div>
							<div v-else>
								<LineChart :chartData="chartData" :options="chartOptions" :height="200"></LineChart>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Loading from "@/components/loading.vue";
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import { LineChart } from "vue-chart-3";
	import { Chart, registerables } from 'chart.js'
	export default {
		components: {
			"loading": Loading,
			LineChart
		},
		props: {},
		emits: [],
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");

			const loading = ref(true);
			const chartData = ref({});
			const chartOptions = ref({});
			const data = ref({});
			const color = "#273d5b";

			const AddZeroes = (labels, dataset) => {
				const tempData = { ...dataset };
				dataset = {};
				for (const label of labels) {
					if (tempData[label] === undefined) {
						dataset[label] = 0;
					} else {
						dataset[label] = tempData[label];
					}
				}
				return dataset;
			}

			const GetDashboard = async () => {
				try {
					var response = await $http.get("api/report/dashboard");
					data.value = response.data;

					//Add zeroes for days without quote requests
					data.value.dataLWeek = AddZeroes(data.value.labelsLWeek, data.value.dataLWeek);
					data.value.dataLMonth = AddZeroes(data.value.labelsLMonth, data.value.dataLMonth);
				}
				catch (err) {
					$error("Failed to load dashboard.");
				}
			}

			const CreateChart = () => {
				chartData.value = {
					datasets: [{
						label: "Quotes",
						data: data.value.dataLMonth,
						borderColor: color,
						backgroundColor: color
					}],
					labels: data.value.labelsLMonth
				};
				chartOptions.value = {
					responsive: true,
					elements: {
						line: {
							tension: 0
						}
					}
				};
			}

			onMounted(async () => {
				await GetDashboard();
				Chart.register(...registerables);
				CreateChart();
				loading.value = false;
			})

			return {
				loading,
				chartData,
				chartOptions
			}
		}
	};
</script>
